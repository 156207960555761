import { Controller } from 'stimulus';

class Styles {
  get toggle() {
    return 'hidden';
  }

  get entering() {
    return ['ease-out', 'duration-100'];
  }

  get leaving() {
    return ['ease-in', 'duration-75'];
  }

  get active() {
    return [];
  }

  get invisible() {
    return ['opacity-0', 'scale-95'];
  }

  get visible() {
    return ['opacity-100', 'scale-100'];
  }
}

export default class extends Controller {
  static targets = ['menu']

  initialize() {
    this.styles = new Styles();
  }

  toggle() {
    if (this.hidden) {
      return this.onShow();
    }

    return this.onHide();
  }

  show() {
    return this.onShow();
  }

  hide() {
    return this.onHide();
  }

  close(event) {
    if (!this.element.contains(event.target) && this.visible) {
      return this.onHide();
    }
  }

  get activeTarget() {
    return this.data.has('activeTarget') &&
      document.querySelector(this.data.get('activeTarget')) ||
      this.element;
  }

  get hidden() {
    return this.menuTarget.classList.contains(this.styles.toggle);
  }

  get visible() {
    return !this.hidden;
  }

  onShow() {
    this.menuTarget.classList.remove(this.styles.toggle);
    this.menuTarget.classList.add(...this.styles.entering);

    function entering() {
      this.activeTarget.classList.add(...this.styles.active);
      this.menuTarget.classList.remove(...this.styles.invisible);
      this.menuTarget.classList.add(...this.styles.visible);

      function activate() {
        this.menuTarget.classList.remove(...this.styles.entering);
      }

      window.setTimeout(activate.bind(this), 100);
    }

    window.setTimeout(entering.bind(this));
  }

  onHide() {
    function leaving() {
      this.activeTarget.classList.remove(...this.styles.active);
      this.menuTarget.classList.remove(...this.styles.visible);
      this.menuTarget.classList.add(...this.styles.leaving);
      this.menuTarget.classList.add(...this.styles.invisible);

      function deactivate() {
        this.menuTarget.classList.remove(...this.styles.leaving);
        this.menuTarget.classList.add(this.styles.toggle);
      }

      window.setTimeout(deactivate.bind(this), 75);
    }

    window.setTimeout(leaving.bind(this));
  }
}
