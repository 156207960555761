/**
 * Handles Drag and Drop of an template placeholder element
 *
 * @params {Object}   [options]     The functions options
 * @params {element}  attribute.key The placeholder element that be dragged and dropped.
 * @params {template} attribute.key The template preview image the placeholder will overlay on.
*/
function draggable({ element, template }) {
  let state = {
    dragging: false,
    position: {
      top:    0,
      left:   0,
      width:  0,
      height: 0,
    },
    mouse: {
      top: undefined,
      left: undefined,
    },
  };

  /**
   * Listens to `mousedown` event.
   *
   * @param {Object} event - The event.
   */
  function onMouseDown(event) {
    event.preventDefault();

    setState({
      dragging: true,
      position: { left: getLeftPosition(), top: getTopPosition() },
      mouse: { top: event.clientY, left: event.clientX }
    });

    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
  }

  /**
   * Listens to `mouseup` event.
   *
   * @param {Object} event - The event.
   */
  function onMouseUp(event) {
    event.preventDefault();

    setState({
      dragging: false,
      position: {
        left: getLeftPosition(),
        top: getTopPosition(),
        width: getWidth(),
        height: getHeight()
      }
    });

    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  }

  /**
   * Listens to `mousemove` event.
   *
   * @param {Object} event - The event.
   */
  function onMouseMove(event) {
    event.preventDefault();

    const { dragging } = state;

    if (!dragging) {
      return;
    }

    const { top, left } = state.position;

    let content = element.querySelector('.content');
    let offsetX = content.offsetWidth - content.clientWidth;
    let offsetY = content.offsetHeight - content.clientHeight;
    let deltaX  = event.clientX - state.mouse.left;
    let deltaY  = event.clientY - state.mouse.top;

    element.style.left = left + deltaX + 'px';
    element.style.top  = top + deltaY + 'px';
  }

  /**
   * Gets position for a X axis
   *
   */
  function getLeftPosition() {
    return Math.min(getPosition(element.style.left), template.width);
  }

  /**
   * Gets position for a Y axis
   *
   */
  function getTopPosition() {
    return Math.min(getPosition(element.style.top), template.height);
  }

  /**
   * Gets position for a specified axis
   *
   * @param {string} axis - style property.
   */
  function getPosition(axis) {
    return Math.max(...[parseInt(axis) || 0, 0]);
  }

  /**
   * Calculates width of placeholder element.
   *
   */
  function getWidth() {
    const styles = window.getComputedStyle(element.querySelector('.content'));
    console.log(element.style.width, element.clientWidth, element.offsetWidth);
    return parseInt(styles.width, 10);
  }

  /**
   * Calculates height of placeholder element.
   *
   */
  function getHeight() {
    let fontSize       = parseInt(element.dataset.size);
    let relativeHeight = parseInt(fontSize * 1.6);
    let templateHeight = template.height;

    return Math.min(relativeHeight, templateHeight)
  }

  /**
   * Sets coordinates values to hidden fields
   *
   * @param {Object} position - The new state of elements coordinates
  */
  function setState(newState) {
    const { target } = element.dataset;

    state = { ...state, ...newState };

    for (const key in state.position) {
      const hidden = document.querySelector(`#template_${target}_position_${key}`);
      hidden.setAttribute('value', `${state.position[key]}px`);
    }

    const width  = document.querySelector('#template_width');
    const height = document.querySelector('#template_height');

    width.setAttribute('value', `${template.width}px`);
    height.setAttribute('value', `${template.height}px`);

    console.log({ ...state.position });
  }

  // mouse button down over the element
  element.querySelector('.heading').addEventListener('mousedown', onMouseDown);
}

export { draggable };
