import './component.scss';

function ready() {
  scrollTo(document.querySelector('.tab-group_tab.active'));
}

function scrollTo(element) {
  if (!element) {
    return;
  }

  const elLeft = element.offsetLeft + element.offsetWidth;
  const elParentLeft = element.parentNode.offsetLeft + element.parentNode.offsetWidth;

  // check if element not in view
  if (elLeft >= elParentLeft + element.parentNode.scrollLeft) {
    element.parentNode.scrollLeft = elLeft - elParentLeft;
  } else if (elLeft <= element.parentNode.offsetLeft + element.parentNode.scrollLeft) {
    element.parentNode.scrollLeft = element.offsetLeft - element.parentNode.offsetLeft;
  }
}

document.addEventListener("turbolinks:load", ready);
