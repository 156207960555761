import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['actions', 'index', 'form'];

  connect() {
    this.fetch();
  }

  fetch() {
    function handle(html) {
      this.indexTarget.innerHTML = html;
    }

    fetch(this.indexPath)
      .then(response => response.text())
      .then(handle.bind(this));
  }

  add(event) {
    function handle(html) {
      this.formTarget.innerHTML = html;
    }

    this.fetch();

    fetch(event.target.dataset.addPath)
      .then(response => response.text())
      .then(handle.bind(this));
  }

  edit(event) {
    event.preventDefault();

    function handle(html) {
      this.formTarget.innerHTML = '';
      this.indexTarget.innerHTML = html;
    }

    fetch(event.target.dataset.editPath)
      .then(response => response.text())
      .then(handle.bind(this));
  }

  cancel(event) {
    event.preventDefault();

    this.formTarget.innerHTML = '';
    this.fetch();
  }

  render(event) {
    const [data, status, xhr] = event.detail;

    if (data.includes("error")) {
      if (event.target.querySelector('[name="_method"]')) {
        this.indexTarget.innerHTML = xhr.response;
      } else {
        this.formTarget.innerHTML = xhr.response;
      }
      return;
    }

    this.formTarget.innerHTML = '';
    this.fetch();
  }

  get indexPath() {
    return this.data.get('path');
  }
}
