import { Controller } from 'stimulus';

export default class extends Controller {
  allSelected(event) {
    const { target } = event;
    let prints = this.element.querySelectorAll('input[type="checkbox"].order.ready_for_print')

    for (const print of prints) {
      if (target.checked) {
        print.checked = true
      } else {
        print.checked = false
      }

      print.dispatchEvent(new Event('change'));
    }
  }
}
