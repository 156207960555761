import { Controller } from 'stimulus';
import { draggable } from 'src/draggable';
import { resizable } from 'src/resizable';

export default class extends Controller {
  initialize() {
    draggable({ element: this.element, template: document.querySelector('.dropzone')});
    resizable({ element: this.element });
  }

  connect() {
  }
}
