import { Controller } from 'stimulus';
import CableReady from 'cable_ready';

export default class extends Controller {
  static targets = ['button', 'form', 'loading'];

  connect() {
    this.channel = this.createChannel();
    this.element.addEventListener('selected-print-queue:empty', this.disable.bind(this));
    this.element.addEventListener('selected-print-queue:present', this.enable.bind(this));
    this.element.addEventListener('selected-print-queue:printed', this.completed.bind(this));
  }

  disconnect() {
    this.channel.unsubscribe();
    this.element.removeEventListener('selected-print-queue:empty', this.disable.bind(this));
    this.element.removeEventListener('selected-print-queue:present', this.enable.bind(this));
    this.element.removeEventListener('selected-print-queue:printed', this.completed.bind(this));
  }

  createChannel() {
    return this.application.consumer.subscriptions.create({ id: this.element.dataset.printQueueId, channel: 'Prints::Channels::PrintChannel'}, {
      received(data) {
        if (data.cableReady) {
          CableReady.perform(data.operations);
        }
      }
    });
  }

  printOrders(event) {
    event.preventDefault();

    function showLoading(response) {
      if (response === 'ok') {
        this.loadingTarget.classList.remove('hidden');
        console.log('show loading screen');
      }
    }

    fetch(this.action, this.params)
      .then(response => response.json())
      .then(showLoading.bind(this))
      .catch(error => console.log(error));
  }

  enable(event) {
    this.buttonTarget.classList.remove('md:hidden');
  }

  disable(event) {
    this.buttonTarget.classList.add('md:hidden');
  }

  completed(event) {
    this.loadingTarget.classList.add('hidden');

    const { documentPath, redirectPath } = event.detail;

    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: documentPath,
    }).click();

    Turbolinks.visit(redirectPath, { action: 'advance' });
  }

  get action() {
    return this.formTarget.action;
  }

  get params() {
    let token = document.querySelector('meta[name="csrf-token"]').content;

    return {
      method: this.formTarget.method,
      body: new FormData(this.formTarget),
      headers: {
        'X-CSRF-Token': token
      }
    };
  }
}
