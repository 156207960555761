import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'edit', 'form', 'header', 'package', 'preview','price', 'route', 'volume'];

  connect() {
    this.loadFont(this.element.dataset.fontUrl);


    if (this.routeTarget.options.length == 2) {
      this.routeTarget.options[1].selected = true;
      this.routeTarget.dispatchEvent(new Event('change'));
    }
  }

  loadFont(url) {
    if (!url) {
      return;
    }

    let font = new FontFace("TemplateFont", `url(${url}) format("truetype")`);

    function addFont(font) {
      document.fonts.add(font);
    }

    font.load().then(addFont);
  }


  previewOrder(event) {
    event.preventDefault();

    const previewActions = this.element.querySelector('.mobile .previews');
    const orderActions   = this.element.querySelector('.mobile .order');

    this.editTarget.classList.add('hidden');
    this.headerTarget.classList.add('hidden');
    this.previewTarget.classList.add('block');
    this.previewTarget.style.display = 'block';
    previewActions.classList.add('hidden');
    orderActions.classList.remove('hidden');
  }

  editOrder(event) {
    event.preventDefault();

    const previewActions = this.element.querySelector('.mobile .previews');
    const orderActions   = this.element.querySelector('.mobile .order');

    this.previewTarget.style.display = 'none';
    orderActions.classList.add('hidden');
    this.editTarget.classList.remove('hidden');
    this.headerTarget.classList.remove('hidden');
    previewActions.classList.remove('hidden');
  }

  previewChanged(event) {
    const { target, value } = event;
    const _target      = target.dataset.placeholder;
    const placeholders = document.querySelectorAll(`.placeholder.${_target} .content`)

    for (const placeholder of placeholders) {
      if (_target === 'sku') {
        let sku = [this.packageText, this.volumeText, this.containerText].join(' ')
        placeholder.innerHTML = sku;
      } else if(_target === 'route') {
        placeholder.innerHTML = target.options[target.selectedIndex].text;
      }
      else {
        placeholder.innerHTML = target.value;
      }

    }
  }

  routeChanged(event) {
    event.preventDefault();

    const { id, value } = event.target;

    console.log(value);

    if (!value) {
      return;
    }

    function render(response) {
      const customers = this.element.querySelector('#customers');
      customers.innerHTML = response;
    }

    fetch(this.path)
      .then(response => response.text())
      .then(render.bind(this))
      .catch(error => console.log(error));
  }

  customerAdded(event) {
    if (!this.routeTarget.value) {
      event.preventDefault();
      return;
    }

    let customers   = this.element.querySelector('#customers .customers');
    let index       = customers.dataset.customers;
    let addCustomer = this.element.querySelector('#add-customer-template');

    customers.append(addCustomer.content.firstElementChild.cloneNode(true));

    addCustomer = customers.lastElementChild;
    addCustomer.innerHTML = addCustomer.innerHTML.replaceAll('{{index}}', index);
    customers.dataset.customers = parseInt(index) + 1;
  }

  customerRemoved(event) {
    const { target } = event;
    let customers    = this.element.querySelector('#customers .customers');
    let index        = customers.dataset.customers;

    customers.dataset.customers = parseInt(index) - 1;
    target.closest('.customer').remove();
  }

  get path() {
    const rootPath = this.routeTarget.dataset.path;
    let params     = this.params;

    return [rootPath, params].join('?');
  }

  get params() {
    function empty(param) {
      const [key, value] = param;
      return value !== undefined;
    }

    function asQueryString(param) {
      return param.join('=');
    }

    return [
      ['route_id', this.routeTarget.value],
      ['customers', this.formTarget.dataset.customers],
      ['container_id', this.containerTarget.value],
      ['package_id', this.packageTarget.value],
      ['price', this.priceTarget.value],
      ['volume_id', this.volumeTarget.value]
    ].filter(empty).map(asQueryString).join('&');
  }

  get containerText() {
    return this.containerTarget.value !== '' &&
      this.containerTarget.options[this.containerTarget.selectedIndex].text ||
      '';
  }

  get packageText() {
    return this.packageTarget.value !== '' &&
      this.packageTarget.options[this.packageTarget.selectedIndex].text ||
      '';
  }

  get volumeText() {
    return this.volumeTarget.value !== '' &&
      this.volumeTarget.options[this.volumeTarget.selectedIndex].text ||
      '';
  }
}
