import { Controller } from 'stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
  static targets = ['select'];

  connect() {
    this.component = new TomSelect(this.selectTarget, this.config);
    this.form.addEventListener(`${this.namespace}:deselected`, this.deselected.bind(this));
    this.form.addEventListener(`${this.namespace}:selected`, this.selected.bind(this));
  }

  disconnect() {
    if (this.component) {
      this.component.destroy();
      this.component = undefined;
    }
  }

  get config() {
    return {
      persist: false,
      itemClass: this.itemClass,
      sortField: {field: "text"},
      onItemAdd: function(item) {
        const selected = this.component.options[item]
        this.dispatch(`${this.namespace}:selected`, {
          item,
          id: this.selectTarget.id,
          name: selected.text,
          externalId: selected.value
        });
      }.bind(this)
    };
  }

  get namespace() {
    return `route-form-${this.selectTarget.id}`;
  }

  get itemClass() {
    const { itemClass } = this.element.dataset;
    return itemClass && itemClass || 'hidden'
  }

  get form() {
    return this.element.closest('form');
  }

  deselected(event) {
    const { id, item } = event.detail;

    if (id !== this.selectTarget.id) {
      return;
    }

    this.component.removeItem(item, true);
    this.component.refreshItems();
  }

  selected(event) {
    const { id, item, data } = event.detail;

    if (id !== this.selectTarget.id) {
      return;
    }
  }

  dispatch(eventName, detail) {
    this.form.dispatchEvent(new CustomEvent(eventName, { detail }));
  }
}
