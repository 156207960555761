import { Controller } from 'stimulus';
import Litepicker from 'litepicker';

export default class extends Controller {
  static targets = ['picker'];

  connect() {
    new Litepicker({ element: this.pickerTarget });
  }
}
