import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'selector', 'brand'];

  connect() {
    this.element.addEventListener('direct-upload:initialize', this.init.bind(this));
    this.element.addEventListener('direct-upload:start', this.start.bind(this));
    this.element.addEventListener('direct-upload:progress', this.progress.bind(this));
    this.element.addEventListener('direct-upload:end', this.finish.bind(this));
    document.querySelector(".main-content").classList.add("templates");
    this.loadFont(this.element.dataset.fontUrl);
  }

  unpublished(event) {
    event.preventDefault();

    const status = this.element.querySelector("#template_status");
    status.setAttribute('value', 'unpublished');

    this.element.querySelector('input[type="submit"]').click();
  }

  published(event) {
    event.preventDefault();

    const status = this.element.querySelector("#template_status");
    status.setAttribute('value', 'published');
    this.element.querySelector('input[type="submit"]').click();
  }

  sizeChanged(event) {
    const { target, detail } = event;
    const { value } = target;
    const placeholder = document.querySelector(target.dataset.placeholder);

    placeholder.style.fontSize = `${Math.ceil(value * this.fontsizeMultiplier)}pt`;
    placeholder.parentElement.dataset.size = value;
  }

  supplierChanged(event) {
    const { target, detail } = event;
    const { id, value } = target;
    const url = `${target.dataset.path}?supplier_id=${value}`;

    function createOption(text, value) {
      let option = document.createElement('option');
      option.text = text;
      option.value = value;

      return option;
    }

    function handle(response) {
      while (this.brandTarget.options.length > 0) {
        this.brandTarget.remove(0);
      }

      this.brandTarget.add(createOption('-- Select Brand --', ""));

      for (let brand of response['brands']) {
        this.brandTarget.add(createOption(brand[0], brand[1]));
      }
    }

    fetch(url)
      .then(response => response.json())
      .then(handle.bind(this))
      .catch(error => console.log(error));
  }

  fileSelected(event) {
    const { target, detail } = event;
    const file = target.files[0];

    this.selectorTarget.setAttribute('placeholder', file.name);
  }

  fontSelected(event) {
    const { target, detail } = event;
    this.loadFont(target.options[target.selectedIndex].dataset.fontUrl);
  }

  loadFont(url) {
    if (!url) {
      return;
    }

    let font = new FontFace("TemplateFont", `url(${url}) format("truetype")`);

    function addFont(font) {
      document.fonts.add(font);
    }

    font.load().then(addFont);
  }

  init(event) {
    const { target, detail } = event;
    const { id, file } = detail;
    const upload = document.querySelector(`.progress-bar`)

    upload.innerHTML =
    `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div class="direct-upload--bar">
          <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
          <span class="direct-upload__filename"></span>
        </div>
        <span class="direct-upload--progress">0% Complete</span>
      </div>
    `;
  }

  start(event) {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);

    element.classList.remove('direct-upload--pending');
  }

  progress(event) {
    const { id, progress } = event.detail;
    const element = document.getElementById(`direct-upload-progress-${id}`);
    const percentage = document.querySelector('.direct-upload--progress');

    element.style.width = `${progress}%`;
    percentage.innerHTML = `${progress}% Complete`;
  }

  finish(event) {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);

    element.classList.add('direct-upload--complete');
  }

  get fontsizeMultiplier() {
    const template = document.querySelector('#zone');

    if (template.clientWidth < 1000) {
      return 1.5;
    }

    return 3;
  }
}
