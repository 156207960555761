// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import consumer from '../channels/consumer'

const application = Application.start();
const controllers = require.context("controllers", true, /_controller\.js$/);
const components = require.context("../../components", true, /_controller.js$/);
const domains    = require.context("../../domain", true, /_controller.js$/);
const dependencies = definitionsFromContext(controllers).concat(definitionsFromContext(components)).concat(definitionsFromContext(domains));

application.consumer = consumer
application.load(dependencies);
