import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['color', 'preview'];

  cmykToRgb(event) {
    const { target, detail } = event;
    const { id, value } = target;
    const placeholder = document.querySelector(target.dataset.placeholder);
    const [c, m, y, k] = value.split(',').map(n => Number(parseInt(n)) / 100);

    var r, g, b;

    r = 255 - ((Math.min(1, c * (1 - k) + k)) * 255);
    g = 255 - ((Math.min(1, m * (1 - k) + k)) * 255);
    b = 255 - ((Math.min(1, y * (1 - k) + k)) * 255);

    this.previewTarget.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
    placeholder.style.color = `rgb(${r}, ${g}, ${b})`;
  }
}
