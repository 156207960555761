import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'selector', 'submit'];

  connect() {
    this.element.addEventListener('direct-upload:initialize', this.init.bind(this));
    this.element.addEventListener('direct-upload:start', this.start.bind(this));
    this.element.addEventListener('direct-upload:progress', this.progress.bind(this));
    this.element.addEventListener('direct-upload:end', this.finish.bind(this));
  }

  open(event) {
    event.preventDefault();
    this.selectorTarget.click();
  }

  selected(event) {
    this.submitTarget.click();
  }

  submit(event) {
    this.submitTarget.click();
  }

  init(event) {
    const { target, detail } = event;
    const { id, file } = detail;
    const upload = document.querySelector(`.progress-bar`)

    upload.innerHTML =
    `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div class="direct-upload--bar">
          <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
          <span class="direct-upload__filename"></span>
        </div>
        <span class="direct-upload--progress">0% Complete</span>
      </div>
    `;
  }

  start(event) {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);

    element.classList.remove('direct-upload--pending');
  }

  progress(event) {
    const { id, progress } = event.detail;
    const element = document.getElementById(`direct-upload-progress-${id}`);
    const percentage = document.querySelector('.direct-upload--progress');

    element.style.width = `${parseInt(progress)}%`;
    percentage.innerHTML = `${parseInt(progress)}% Complete`;
  }

  finish(event) {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);

    element.classList.add('direct-upload--complete');
  }
}
