import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['up', 'down', 'body'];

  toggle() {
    if (this.hidden) {
      return this.show();
    }

    return this.hide();
  }

  show() {
    function start() {
      this.upTarget.classList.remove('hidden');
      this.bodyTarget.classList.remove('hidden');
      this.downTarget.classList.add('hidden');

      function finish() {
        this.downTarget.classList.add('block');
        this.bodyTarget.classList.add('block');
        this.upTarget.classList.remove('block');
      }

      window.setTimeout(finish.bind(this), 100);
    }

    window.setTimeout(start.bind(this));
  }

  hide() {
    function start() {
      this.upTarget.classList.remove('block');
      this.bodyTarget.classList.remove('block');
      this.downTarget.classList.add('block');

      function finish() {
        this.upTarget.classList.add('hidden');
        this.bodyTarget.classList.add('hidden');
        this.downTarget.classList.remove('hidden');
      }

      window.setTimeout(finish.bind(this), 75);
    }

    window.setTimeout(start.bind(this));
  }

  get hidden() {
    return this.bodyTarget.classList.contains('hidden');
  }
}
