import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['empty', 'preview'];

  fileSelected(event) {
    const { target, detail } = event;
    const file   = target.files[0];
    const reader = new FileReader();

    function onload(e) {
      const image = this.previewTarget.querySelector('img');

      this.emptyTarget.classList.add('hidden');
      this.previewTarget.classList.remove('hidden');

      image.setAttribute('src', e.target.result);
    }

    reader.onload = onload.bind(this)
    reader.readAsDataURL(file);
  }
}
