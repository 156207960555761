
function resizable({ element }) {
  let state = {
    resizing: false,
    position: {
      width: 0,
      height: 0
    },
    mouse: {
      top: undefined,
      left: undefined
    }
  }

  function onMouseDown(event) {
    event.preventDefault();
    console.log("resizable", "onMouseDown", event);

    // Calculate the dimension of element
    const styles = window.getComputedStyle(element.querySelector('.content'));

    setState({
      resizing: true,
      position: { height: parseInt(styles.height, 10), width: parseInt(styles.width, 10) },
      mouse: { top: event.clientY, left: event.clientX }
    });

    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
  }

  function onMouseUp(event) {
    event.preventDefault();
    console.log("resizable", "onMouseUp", event);

    // Calculate the dimension of element
    const styles = window.getComputedStyle(element.querySelector('.content'));

    setState({
      resizing: false,
      position: { height: parseInt(styles.height, 10), width: parseInt(styles.width, 10) }
    });

    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  }

  function onMouseMove(event) {
    event.preventDefault();

    const { resizing } = state;

    if (!resizing) {
      return;
    }

    const { height, width } = state.position;
    const { top, left } = state.mouse;

    let content = element.querySelector('.content');
    let w = event.clientX - left;
    let h = event.clientY - top;

    console.log("width", width, "clientX", event.clientX, "w", w);

    content.style.width = `${w + width}px`;
    content.style.height = `${h + height}px`;
  }

  /**
   * Gets position for a X axis
   *
   */
  function getLeftPosition() {
    return Math.min(getPosition(element.style.left));
  }

  /**
   * Gets position for a Y axis
   *
   */
  function getTopPosition() {
    return Math.min(getPosition(element.style.top));
  }

  /**
   * Gets position for a specified axis
   *
   * @param {string} axis - style property.
   */
  function getPosition(axis) {
    return Math.max(...[parseInt(axis) || 0, 0]);
  }

  /**
   * Sets coordinates values to hidden fields
   *
   * @param {Object} position - The new state of elements coordinates
  */
  function setState(newState) {
    state = { ...state, ...newState };

    const { target } = element.dataset;
    const hidden = document.querySelector(`#template_${target}_position_width`);

    hidden.setAttribute('value', `${state.position.width}px`);

    console.log("resizable", "setState", state);
  }

  // mouse button down over the element
  element.querySelector('.resizer-b').addEventListener('mousedown', onMouseDown);
}

export { resizable };
