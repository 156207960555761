import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'edit', 'form', 'header', 'package', 'preview','price', 'volume'];

  connect() {
    this.loadFont(this.element.dataset.fontUrl);
  }

  loadFont(url) {
    if (!url) {
      return;
    }

    let font = new FontFace("TemplateFont", `url(${url}) format("truetype")`);

    function addFont(font) {
      document.fonts.add(font);
    }

    font.load().then(addFont).catch(function(error) {
      // console.log(error);
    });
  }

  routeChanged(event) {
  }

  previewChanged(event) {
    const { target, value } = event;
    const skues  = document.querySelectorAll('.placeholders .placeholder.sku .content');
    const prices = document.querySelectorAll('.placeholders .placeholder.price .content');

    for (let i = 0; i < skues.length; i++) {
      skues[i].innerText = this.sku;
    }

    for (let i = 0; i < prices.length; i++) {
      prices[i].innerText = this.price;
    }
  }

  previewOrder(event) {
    event.preventDefault();

    const previewActions = this.element.querySelector('.mobile .previews');
    const orderActions   = this.element.querySelector('.mobile .order');

    this.editTarget.classList.add('hidden');
    this.headerTarget && this.headerTarget.classList.add('hidden');
    this.previewTarget.classList.add('block');
    previewActions.classList.add('hidden');
    orderActions.classList.remove('hidden');
    this.previewTarget.style.display = 'block';
  }

  editOrder(event) {
    event.preventDefault();

    const previewActions = this.element.querySelector('.mobile .previews');
    const orderActions   = this.element.querySelector('.mobile .order');

    this.previewTarget.style.display = 'none';
    orderActions.classList.add('hidden');
    this.editTarget.classList.remove('hidden');
    this.headerTarget && this.headerTarget.classList.remove('hidden');
    previewActions.classList.remove('hidden');
  }

  get price() {
    return this.priceTarget.value;
  }

  get sku() {
    return [
      this.packageTarget.options[this.packageTarget.selectedIndex].text,
      this.volumeTarget.options[this.volumeTarget.selectedIndex].text,
      this.containerTarget.options[this.containerTarget.selectedIndex].text
    ].join(' ').toLowerCase();
  }
}


