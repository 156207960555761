import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['article', 'empty'];

  connect() {
    for (const namespace of this.namespaces) {
      this.form.addEventListener(`route-form-${namespace}:selected`, this.selected.bind(this));
    }
  }

  disconnect() {
    for (const namespace of this.namespaces) {
      this.form.removeEventListener(`route-form-${namespace}:selected`, this.selected.bind(this));
    }
  }

  deselect(event) {
    const { target } = event;
    const { id, ...data } = target.closest('a').dataset;
    const section = this.element.querySelector(`.selected.${id}`);
    const article = target.closest('article');

    if (article == undefined) {
      return;
    }

    article.remove();

    if (section.childElementCount <= 0) {
      let empty = this.emptyTarget.content.firstElementChild.cloneNode(true);
      section.append(empty);
    }

    this.dispatch(`route-form-${id}:deselected`, {
      id,
      ...data
    });
  }

  selected(event) {
    const { id }  = event.detail;
    const section = this.element.querySelector(`.selected.${id}`);
    const empty   = section.querySelector('.empty-state');
    let article   = this.clone(event);

    section.prepend(article);

    if (empty) {
      empty.remove();
    }
  }

  clone(event) {
    const { detail } = event;
    let article = this.articleTarget.content.firstElementChild.cloneNode(true);

    for (const param in detail) {
      article.innerHTML = article.innerHTML.replaceAll(`{{${param}}}`, detail[param]);
    }

    return article;
  }

  dispatch(eventName, detail) {
    this.form.dispatchEvent(new CustomEvent(eventName, { detail }));
  }

  get namespaces() {
    return ['sales_representatives', 'managers', 'customers'];
  }

  get form() {
    return this.element.closest('form');
  }
}
