import { Controller } from 'stimulus';

export default class extends Controller {
  printChanged(e) {
    if (this.selected.length > 0) {
      return this.dispatch('selected-print-queue:present');
    }

    return this.dispatch('selected-print-queue:empty');
  }

  dispatch(e) {
    const event = new CustomEvent(e, { bubbles: true, detail: this.selected });
    this.printQueue.dispatchEvent(event);
  }

  get selected() {
    return this.element.querySelectorAll('input[type="checkbox"].order:checked');
  }

  get printQueue() {
    return document.querySelector('.print--queue--component');
  }
}
