import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['menu', 'opened', 'closed']

  toggle() {
    if (this.hidden) {
      return this.show();
    }

    return this.hide();
  }

  show() {
    function start() {
      this.menuTarget.classList.remove('hidden');
      this.openedTarget.classList.remove('hidden');
      this.closedTarget.classList.add('hidden');

      function finish() {
        this.menuTarget.classList.add('block');
        this.openedTarget.classList.add('block');
        this.closedTarget.classList.remove('block');
      }

      window.setTimeout(finish.bind(this), 100);
    }

    window.setTimeout(start.bind(this));
  }

  hide() {
    function start() {
      this.menuTarget.classList.remove('block');
      this.closedTarget.classList.remove('block');
      this.openedTarget.classList.add('block');

      function finish() {
        this.menuTarget.classList.add('hidden');
        this.openedTarget.classList.add('hidden');
        this.closedTarget.classList.remove('hidden');
      }

      window.setTimeout(finish.bind(this), 75);
    }

    window.setTimeout(start.bind(this));
  }

  close(event) {
    if (!this.element.contains(event.target) && this.visible) {
      return this.hide();
    }
  }

  get hidden() {
    return this.menuTarget.classList.contains('hidden');
  }

  get visible() {
    return !this.hidden;
  }
}
